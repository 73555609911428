<div class="flex flex-between-center">
    <h5 class="u-mt16">Advisor Details</h5>
    @if (!removeButton()) {
    <button class="aix-btn aix-btn--link br-link u-mt8" type="button" (click)="onClickRemove()">
        <span class="fa-regular fa-trash-alt u-mr8"></span>Remove
    </button>
    }
</div>

<form [formGroup]="_form">
    <div class="aix-form__container u-mt16">
        <aix-input
            formControlName="fullName"
            [config]="{ refId: 'fullName' }"
            [isValid]="_form.get('fullName')?.valid"
            [isRequired]="true"
            [placeholder]="'Advisor first and last name'"
        >
            Advisor Name
        </aix-input>
    </div>

    <div class="aix-form__container u-mt16">
        <aix-input
            formControlName="crdNumber"
            [config]="{ refId: 'crdNumber' }"
            [isValid]="_form.get('crdNumber')?.valid"
            [isRequired]="true"
            [placeholder]="'Advisor CRD number'"
        >
            Advisor CRD Number
        </aix-input>
    </div>

    <div class="aix-form__container u-mt16">
        <aix-input
            formControlName="repCodes"
            [config]="{ refId: 'repCodes' }"
            [isValid]="_form.get('repCodes')?.valid"
            [isRequired]="true"
            [placeholder]="'Advisor Rep Code(s)'"
        >
            Advisor Rep Code(s)
        </aix-input>
    </div>

    <div class="aix-form__container u-mt16">
        <aix-input
            formControlName="email"
            [config]="{ refId: 'email' }"
            [isValid]="_form.get('email')?.valid"
            [isRequired]="true"
            [placeholder]="'Advisor email address'"
        >
            Advisor Email Address
        </aix-input>

        @if (_form.value?.email && _form.get('email')?.invalid) {
        <div class="form-error--container">
            <div class="form-error">Email Address field is invalid</div>
        </div>
        }
    </div>
</form>
