import {
    Component,
    forwardRef,
    HostBinding,
    input,
    model,
    OnChanges,
    output,
    SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AixTooltipDirective } from '../../directives/tooltip/aix-tooltip';
import { NgClass, NgIf } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-checkbox',
    styleUrls: ['./aix-checkbox.component.scss'],
    templateUrl: './aix-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixCheckboxComponent)
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective, NgClass, NgIf, AixTooltipDirective]
})
export class AixCheckboxComponent implements OnChanges, ControlValueAccessor {
    _propagateChanges: (value: boolean) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    config = input<any>();
    isStandalone = input<boolean>(true);
    isRequired = input<boolean>(false);
    isDisabled = input<boolean>(false);
    isValid = input<boolean>(false);
    name = input<string>('');
    placeholder = input<string>('');
    bodyClassNames = input<string[]>();
    headerClassNames = input<string[]>();
    infoTooltip = input<string>('');
    initialValue = input<boolean>();

    isDirty = model<boolean>(false);
    valueToRender = model<boolean>();

    valueChanges = output<boolean>();
    setDirty = output();

    @HostBinding('attr.aix-control')
    aixControl: string;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initialValue) {
            this.valueToRender.set(this.initialValue());
        }
    }

    writeValue(value: boolean) {
        this.valueToRender.set(value);
        return this.valueToRender();
    }

    valueToSend(e: Event) {
        const val = (e.target as HTMLInputElement).checked;
        this.valueToRender.set(val);
    }

    onUserInput(evt: Event | ClipboardEvent) {
        const val = (evt.target as HTMLInputElement).checked;

        if (!this.isDirty()) {
            this.setDirty.emit();

            // It's standalone, we set the dirty state here instead through the store
            if (this.isStandalone()) {
                this.isDirty.set(true);
            }
        }

        this._propagateChanges(val);
        this.valueChanges.emit(val);
    }

    registerOnChange(fn: (value: boolean) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
