import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    input,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    viewChild,
    ViewChild
} from '@angular/core';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    AixNotificationComponent,
    AixRadioGroupComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrder, PreSignatureReviewType } from '@trade-platform/ui-shared';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { orderStatus } from '@trade-platform/lib-enums';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';
import { DatePipe, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'aix-pre-review',
    templateUrl: './pre-review.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixHeaderSectionComponent,
        AixDataTestingDirective,
        NgIf,
        AixButtonComponent,
        FormsModule,
        ReactiveFormsModule,
        AixRadioGroupComponent,
        NgFor,
        DatePipe
    ]
})
export class AixOrderPreReviewComponent implements OnInit, OnChanges, OnDestroy {
    notification = viewChild<AixNotificationComponent>(AixNotificationComponent);

    order = input<BaseOrder>();
    isRequired = input<boolean>(false);
    inPreviewSend = input<boolean>(false);

    onCancelReview = output();
    onChangeSelection = output<PreSignatureReviewType>();

    orderStatus = orderStatus;
    deliveryForm: UntypedFormGroup;
    preSignatureReviewMethod: PreSignatureReviewType;
    preSignatureReviewMethods = [
        {
            value: 'send-for-review' as PreSignatureReviewType,
            title: 'Send for Review',
            description: 'Send a preview of this order for review prior to collecting signatures.',
            disabled: false
        },
        {
            value: 'collect-signatures' as PreSignatureReviewType,
            title: 'Collect Signatures',
            description: 'Proceed to collecting signatures.',
            disabled: false
        }
    ];

    continueButtonType = BUTTON_TYPE.primary;
    linkButtonType = BUTTON_TYPE.link;

    subscriptions: Subscription[] = [];

    constructor(
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        private ref: ChangeDetectorRef,
        private _fb: UntypedFormBuilder
    ) {
        this.deliveryForm = this._fb.group({
            preSignatureReviewMethod: this._fb.control({}, Validators.required)
        });
    }

    ngOnInit() {
        this.preSignatureReviewMethod = 'send-for-review';
        this.deliveryForm.get('preSignatureReviewMethod')?.setValue('send-for-review');
        this.onChangeSelection.emit('send-for-review');

        this.subscriptions.push(
            this.deliveryForm.valueChanges.subscribe(() => {
                this.onChangeSelection.emit(this.deliveryForm.value.preSignatureReviewMethod);
                this.preSignatureReviewMethod = this.deliveryForm.value.preSignatureReviewMethod;
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isRequired) {
            this.preSignatureReviewMethods[1].disabled = this.isRequired();
            if (this.isRequired()) {
                this.deliveryForm.get('preSignatureReviewMethod')?.setValue('send-for-review');
                this.onChangeSelection.emit('send-for-review');
            }
        }
    }

    cancelReview() {
        this.onCancelReview.emit();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
