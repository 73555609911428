import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, viewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AixFileUploadComponent } from '../../file-upload/file-upload';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixDropdownComponent,
    AixHeaderSectionComponent,
    AixModalComponent,
    AixNotificationComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { CommonModule, NgIf } from '@angular/common';
import { orderStatus, orderStatusIndex } from '@trade-platform/lib-enums';
import {
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { profileHasRoles, ProfileStoreFacade } from '@advisor-ui/app-services';
import { constants } from '@trade-platform/ui-shared';

@Component({
    selector: 'aix-order-details-admin',
    templateUrl: './order-admin.html',
    styleUrls: ['./order-admin.scss'],
    standalone: true,
    imports: [
        AixButtonComponent,
        AixDataTestingDirective,
        AixDropdownComponent,
        AixFileUploadComponent,
        AixHeaderSectionComponent,
        AixModalComponent,
        AixNotificationComponent,
        CommonModule,
        NgIf,
        ReactiveFormsModule
    ]
})
export class AixOrdersDetailAdminComponent implements OnInit, OnDestroy {
    readonly reducerSuffix = this.store.type;

    notificationRef = viewChild<AixNotificationComponent>(AixNotificationComponent);
    confirmationModal = viewChild<AixModalComponent>(AixModalComponent);

    notificationMessage = 'hello';
    notificationType: 'ok' | 'error' = 'ok';

    modalText = {
        title: '',
        text: ''
    };
    pendingAction: string | null = null;

    orderId: string;
    status: string;
    isWetsign: boolean;
    canConvertToWetsign: boolean;
    canMoveBackInWorkflowStatus: boolean;
    hasPreviousApproval = false;
    canMoveForwardInWorkflow: boolean;

    subscriptions: Subscription[] = [];

    orderForms: any[] = [];
    updateFormOpen = false;
    updateFormSelection = new UntypedFormGroup({
        formId: new UntypedFormControl('', Validators.required)
    });
    selectedFormId: string | null = null;

    buttonTypeSecondary = BUTTON_TYPE.secondary;
    buttonTypeLink = BUTTON_TYPE.link;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade,
        public ref: ChangeDetectorRef,
        public profileStoreFacade: ProfileStoreFacade
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            (this.route.parent as ActivatedRoute).params.subscribe(params => {
                if (params['orderId']) {
                    this.orderId = params['orderId'];
                }
                const profile = this.profileStoreFacade.profile;
                if (!profileHasRoles([{ name: constants.profileTypes.ORDER_ADMIN }], profile)) {
                    if (this.orderId) {
                        this.router.navigate(this.store.routes.process.overview(this.orderId));
                    } else {
                        this.router.navigate(this.store.routes.status());
                    }
                }
            }),
            this.store.orderSuccess$.subscribe(order => {
                if (this.orderId && order.id !== this.orderId) {
                    this.loadOrder();
                }
                this.status = order.status;
                this.isWetsign = order.wetSign === true;
                this.canConvertToWetsign =
                    orderStatusIndex[this.status] >=
                    orderStatusIndex[orderStatus.pendingApprovalProcess];
                this.canMoveBackInWorkflowStatus = [
                    orderStatus.pendingFirmApproval,
                    orderStatus.pendingCustodianApproval,
                    orderStatus.pendingFundSponsorApproval,
                    orderStatus.pendingSubmissionToCustodian,
                    orderStatus.pendingSubmissionToFundSponsor,
                    orderStatus.pendingSubmissionToTransferAgent,
                    orderStatus.pendingManualSubmission
                ].includes(this.status);
                if (order.approvals && order.approvals.length > 1) {
                    const currentApproval = order.approvals[0];
                    const previousApproval = order.approvals[1];
                    if (
                        (currentApproval.config.firmId &&
                            currentApproval.config.firmId === previousApproval.config.firmId) ||
                        (currentApproval.config.custodianId &&
                            currentApproval.config.custodianId ===
                                previousApproval.config.custodianId) ||
                        (currentApproval.config.fundSponsorId &&
                            currentApproval.config.fundSponsorId ===
                                previousApproval.config.fundSponsorId)
                    ) {
                        this.hasPreviousApproval = true;
                    }
                }
                this.canMoveForwardInWorkflow = [
                    orderStatus.pendingFirmApproval,
                    orderStatus.pendingCustodianApproval,
                    orderStatus.pendingFundSponsorApproval
                ].includes(this.status);

                this.orderForms = Object.entries(order.forms).map(([key, value]) => ({
                    name: `${value.documentOwner} - ${value.name}`,
                    id: key
                }));
            })
        );
        this.store.actions.orderPushUpdatedForm.success$.subscribe(() => {
            this.updateFormOpen = false;
            this.onActionSuccess('Form has been updated.');
        });
        this.store.actions.orderPushUpdatedForm.failure$.subscribe(() => {
            this.onActionFailure('Error updating form.');
        });
        this.store.actions.orderToWetsign.success$.subscribe(() => {
            this.onActionSuccess('Order has been converted to wetsign.');
        });
        this.store.actions.orderToWetsign.failure$.subscribe(() => {
            this.onActionFailure('Error converting order to wetsign.');
        });
        this.store.actions.orderMarkComplete.success$.subscribe(() => {
            this.onActionSuccess('Order has been marked complete.');
        });
        this.store.actions.orderMarkComplete.failure$.subscribe(() => {
            this.onActionFailure('Error marking order complete.');
        });
        this.store.actions.orderNigoToNeedsClarification.success$.subscribe(() => {
            this.onActionSuccess('Order has been converted to Needs Clarification.');
        });
        this.store.actions.orderNigoToNeedsClarification.failure$.subscribe(() => {
            this.onActionFailure('Error converting order to Needs Clarification.');
        });
        this.store.actions.orderMoveBackInWorkflow.success$.subscribe(() => {
            this.onActionSuccess('Order has been moved back in workflow.');
        });
        this.store.actions.orderMoveBackInWorkflow.failure$.subscribe(() => {
            this.onActionFailure('Error moving order back in workflow.');
        });
        this.store.actions.orderMoveForwardInWorkflow.success$.subscribe(() => {
            this.onActionSuccess('Order has been moved forward in workflow.');
        });
        this.store.actions.orderMoveForwardInWorkflow.failure$.subscribe(() => {
            this.onActionFailure('Error moving order forward in workflow.');
        });
    }

    onActionSuccess(message: string) {
        this.notificationMessage = message;
        this.notificationType = 'ok';
        this.notificationRef()?.openNotification();
        this.ref.detectChanges();

        this.loadOrder();
    }

    onActionFailure(message: string) {
        this.notificationMessage = message;
        this.notificationType = 'error';
        this.notificationRef()?.openNotification();
        this.ref.detectChanges();
    }

    loadOrder() {
        this.store.actions.getOrder.dispatch({
            orderId: this.orderId,
            reducerSuffix: this.reducerSuffix
        });
    }

    confirmationModalSelected(option: string) {
        if (option === 'Yes, continue' && this.pendingAction) {
            switch (this.pendingAction) {
                case 'toWetsign':
                    this.store.actions.orderToWetsign.dispatch({
                        orderId: this.orderId
                    });
                    break;
                case 'toNeedsClarification':
                    this.store.actions.orderNigoToNeedsClarification.dispatch({
                        orderId: this.orderId
                    });
                    break;
                case 'markComplete':
                    this.store.actions.orderMarkComplete.dispatch({
                        orderId: this.orderId
                    });
                    break;
                case 'resubmit':
                    this.store.actions.orderResubmit.dispatch({
                        orderId: this.orderId
                    });
                    break;
                case 'updateForm':
                    if (this.selectedFormId) {
                        this.store.actions.orderPushUpdatedForm.dispatch({
                            orderId: this.orderId,
                            formId: this.selectedFormId
                        });
                    }
                    break;
                case 'moveBackInWorkflow':
                    this.store.actions.orderMoveBackInWorkflow.dispatch({
                        orderId: this.orderId
                    });
                    break;
                case 'moveForwardInWorkflow':
                    this.store.actions.orderMoveForwardInWorkflow.dispatch({
                        orderId: this.orderId
                    });
                    break;
                default:
                    break;
            }
        } else {
            this.pendingAction = null;
        }
    }

    onClickUpdateOrder(action: string) {
        this.pendingAction = action;

        this.notificationRef()?.closeNotification();
        this.notificationMessage = '';
        this.notificationType = 'ok';

        switch (action) {
            case 'toWetsign':
                this.modalText = {
                    title: 'Convert to Wetsign?',
                    text: 'Are you sure you want to convert this order to wetsign?'
                };
                break;
            case 'toNeedsClarification':
                this.modalText = {
                    title: 'Convert to Needs Clarification',
                    text: 'Are you sure you want to convert this order to Needs Clarification?'
                };
                break;
            case 'markComplete':
                this.modalText = {
                    title: 'Mark Complete',
                    text: 'Are you sure you want to mark this order as complete?'
                };
                break;
            case 'resubmit':
                this.modalText = {
                    title: 'Resubmit',
                    text: 'Are you sure you want to resubmit this order?'
                };
                break;
            case 'updateForm':
                this.modalText = {
                    title: 'Update Form',
                    text: `Are you sure you want to push the latest version of form id ${this.selectedFormId}?`
                };
                break;
            case 'moveBackInWorkflow':
                this.modalText = {
                    title: 'Move Back In Workflow',
                    text: 'Are you sure you want to move this order back in the workflow?'
                };
                break;
            case 'moveForwardInWorkflow':
                this.modalText = {
                    title: 'Move Forward In Workflow',
                    text: 'Are you sure you want to move this order forward in the workflow?'
                };
                break;
            default:
                break;
        }
        this.confirmationModal()?.openModal();
    }

    toggleUpdateFormOpen() {
        this.updateFormOpen = !this.updateFormOpen;
    }

    selectForm(e: any) {
        if (e?.length === 1) {
            this.selectedFormId = e[0].id;
        } else {
            this.selectedFormId = null;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
